<template>
  <v-container>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center" class="text-center" no-gutters>
      <v-card width="90%" class="pa-10">
        <v-row align="center" justify="center" class="text-center" no-gutters>
          <v-img
            src="@/assets/logo_survey.png"
            max-width="180px"
            max-height="180px"
          />
        </v-row>
        <v-form
          ref="createForm"
          v-model="checkValidate"
          :lazy-validation="lazy"
        >
          <p style="font-size: 24px" class="headtitle">
            บันทึกข้อมูลรายงานผลการปฏิบัติงานชุมชนยั่งยืนผ่านระบบออนไลน์
          </p>
          <p style="font-size: 24px" class="headtitle">(สำหรับหัวหน้าสถานี)</p>
          <!-- <p style="font-size: 24px" class="headtitle">
            เพื่อแก้ไขปัญหายาเสพติด แบบครบวงจร ตามยุทธศาสตร์ชาติ
          </p> -->
          <!-- <p style="font-size: 24px" class="headtitle">
            ตำรวจภูธรภาค {{ checklogin.station }}
          </p> -->
          <br />

          <v-row class="text-left">
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="สถานี"
                outlined
                v-model="data.station"
                :disabled="true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="จังหวัด"
                outlined
                v-model="data.province"
                :disabled="true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-menu
                ref="menufrom"
                v-model="menufrom"
                :close-on-content-click="false"
                :return-value.sync="data.datefrom"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data.datefrom"
                    label="วันที่บันทึกข้อมูลล่าสุด"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    :disabled="true"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="data.datefrom" no-title scrollable>
                  <v-btn text color="primary" @click="menufrom = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menufrom.save(data.datefrom)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="ผู้ที่บันทึกข้อมูลล่าสุด"
                outlined
                v-model="data.staffName"
                :disabled="true"
              ></v-text-field>
            </v-col>
          </v-row>
           <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12">
              <span><b>1) ข้อมูลประชากรและครัวเรือน</b></span>
            </v-col>
          </v-row>

          <v-row class="text-left">
            <v-col cols="12">
              <span>1. จำนวนประชากรอายุ(12-65 ปี)</span>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_1_m"
                label="จำนวนเพศชาย"
                outlined
                dense
                suffix="คน"
                type="number"
                @change="summary1_1()"
                disabled
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_1_f"
                label="จำนวนเพศหญิง"
                outlined
                dense
                suffix="คน"
                type="number"
                @change="summary1_1()"
                disabled
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_1_s"
                label="รวม"
                disabled
                outlined
                dense
                suffix="คน"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>2. จำนวนประชากรในการ X-Ray ตามข้อตกลงชุมชน</span>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_2_m"
                label="จำนวนเพศชาย"
                outlined
                dense
                suffix="คน"
                type="number"
                @change="summary1_2()"
                disabled
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_2_f"
                label="จำนวนเพศหญิง"
                outlined
                dense
                suffix="คน"
                type="number"
                @change="summary1_2()"
                disabled
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_2_s"
                label="รวม"
                disabled
                outlined
                dense
                suffix="คน"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>3. จำนวนตรวจหาสารเสพติด X-Ray</span>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_3_m"
                label="จำนวนเพศชาย"
                outlined
                dense
                suffix="คน"
                type="number"
                @change="summary1_3()"
                disabled
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_3_f"
                label="จำนวนเพศหญิง"
                outlined
                dense
                suffix="คน"
                type="number"
                @change="summary1_3()"
                disabled
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_3_s"
                label="รวม"
                disabled
                outlined
                dense
                suffix="คน"
                type="number"
                @change="summary1_3()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>4. จำนวนพบผู้เสพยาเสพติด</span>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_4_m"
                label="จำนวนเพศชาย"
                outlined
                dense
                suffix="คน"
                type="number"
                @change="summary1_4()"
                disabled
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_4_f"
                label="จำนวนเพศหญิง"
                outlined
                dense
                suffix="คน"
                type="number"
                @change="summary1_4()"
                disabled
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_4_s"
                label="รวม"
                disabled
                outlined
                dense
                suffix="คน"
                type="number"
                @change="summary1_4()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>5. จำนวนครัวเรือนจริงในชุมชน</span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_5"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="ครัวเรือน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>6. ครัวเรือนที่ทำข้อตกลง MOU</span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_6"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="ครัวเรือน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>7. จำนวนคุ้ม</span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_7"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="คุ้ม"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 8. จำนวนสมาชิกคุ้มรวมทุกคุ้ม </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_1_8"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="คน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12">
              <span
                ><b
                  >2) สรุปผลการดำเนินงานตามเกณฑ์การประเมินชุมชนยั่งยืน</b
                ></span
              >
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12" class="ml-4">
              <span
                ><b
                  >2.1)
                  ร้อยละจำนวนผู้เสพยาในชุมชนลดลงจากฐานข้อมูลเริ่มโครงการหลังส่งมอบพื้นที่</b
                ></span
              >
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 2.1.1. จำนวนผู้เสพที่ค้นพบ ก่อนส่งมอบพื้นที่ </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_1_1"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="คน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 2.1.2. จำนวนผู้เข้ารับการบำบัด CBTx </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_1_2"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="คน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.1.3. จำนวนผู้เลิกเสพจากกระบวนการบำบัด CBTx (ตามเอกสารรับรอง
                สธ.)
              </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_1_3"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="คน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.1.4. จำนวนผู้เข้ารับการบำบัดโดยวิธีอื่น (ไม่ใช่ CBTx)</span
              >
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_1_4"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="คน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.1.5. ร้อยละจำนวนผู้เสพลดลงหลังส่งมอบพื้นที่ [(3) / (2) x
                100]</span
              >
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_1_5"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="%"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12" class="ml-4">
              <span
                ><b
                  >2.2)
                  ร้อยละจำนวนผู้ค้าในชุมชนลดลงจากฐานข้อมูลเริ่มโครงการหลังส่งมอบพื้นที่</b
                ></span
              >
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.2.1. จำนวนผู้ค้าก่อนส่งมอบพื้นที่
                (แจ้งเบาะแส+สืบสภาพชุมชน)</span
              >
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_2_1"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="คน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.2.2. จำนวนผู้ค้าหลังส่งมอบพื้นที่ (ตามบันทึกจับกุม+ หลบหนี +
                หมายจับ )</span
              >
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_2_2"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="คน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.2.3. ร้อยละจำนวนผู้ค้าลดลงหลังส่งมอบพื้นที่ [(2) / (1) x
                100]</span
              >
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_2_3"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="%"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12" class="ml-4">
              <span
                ><b
                  >2.3)
                  ร้อยละจำนวนครัวเรือนมีสมาชิกยุ่งเกี่ยวกับยาเสพติดลดลงจากฐานข้อมูลเริ่มโครงการหลังส่งมอบพื้นที่</b
                ></span
              >
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.3.1. จำนวนครัวเรือนมีสมาชิกยุ่งเกี่ยวกับยาเสพติด ( x-ray +
                เบาะแส+ สืบสภาพชุมชน)</span
              >
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_3_1"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="ชุมชน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.3.2.
                จำนวนครัวเรือนมีสมาชิกยุ่งเกี่ยวกับยาเสพติดหลังส่งมอบพื้นที่
                (ตามเอกสารรับรอง สธ. + บันทึกการจับกุม + หลบหนี + (หมายจับ)
              </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_3_2"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="ครัวเรือน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.3.3. ร้อยละจำนวนครัวเรือนลดลงหลังส่งมอบพื้นที่ [(2) / (1) x
                100]</span
              >
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_3_3"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="%"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12" class="ml-4">
              <span
                ><b
                  >2.4) ร้อยละความสำเร็จในการแก้ปัญหาจากระบบการแจ้งเบาะแส</b
                ></span
              >
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 2.4.1. จำนวนการแจ้งเบาะแส</span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_4_1"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="เรื่อง"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 2.3.2. จำนวนเบาะแสที่ดำเนินการแล้ว </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_4_2"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="เรื่อง"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.4.3. ร้อยละความสำเร็จในการแก้ปัญหาจากระบบแจ้งเบาะแส [(2) / (1)
                x 100]</span
              >
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_4_3"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="%"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12" class="ml-4">
              <span
                ><b
                  >2.5)
                  ร้อยละจำนวนผู้เสพยาที่ร่วมโครงการในชุมชนได้รับการดูแลคุณภาพชีวิตที่ดีขึ้นหลังเข้าร่วมโครงการ</b
                ></span
              >
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 2.5.1. จำนวนผู้เข้ารับการบำบัด CBTX </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_5_1"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="คน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 2.5.2. จำนวนผู้ที่เข้ารับการศึกษาต่อ </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_5_2"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="คน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 2.5.3. จำนวนผู้ได้รับการจ้างงาน </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_5_3"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="คน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 2.5.4. จำนวนผู้ได้รับการฝึกอาชีพ </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_5_4"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="คน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span> 2.5.5. จำนวนผู้ได้รับจากการพัฒนาตนเอง </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_5_5"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="คน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.5.6. จำนวนผู้รับได้รับการช่วยเหลือเงิน หรือ
                ปัจจัยในการดำรงชีวิต
              </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_5_6"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="คน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.5.7. รวมจำนวนผู้ที่ได้รับการส่งเสริม (2) + (3) + (4) + (5)+(6)
              </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_5_7"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="คน"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="12">
              <span>
                2.5.8.
                ร้อยละจำนวนผู้เสพยาที่ร่วมโครงการในชุมชนได้รับการดูแลคุณภาพชีวิตที่ดีขึ้น
                [(7) / (1) x 100]
              </span>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 mt-0">
              <v-text-field
                v-model="data.q_2_5_8"
                placeholder="จำนวน"
                type="number"
                disabled
                outlined
                dense
                suffix="%"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12">
              <span
                ><b
                  >3.)รายงานการวิเคราะห์ผลการดำเนินงานโครงการชุมชนยั่งยืน (SWOT
                  Analysis)</b
                ></span
              >
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left mt-4" no-gutters>
            <v-col cols="12" class="ml-4">
              <span><b>3.1 จุดแข็ง</b></span>
            </v-col>
            <v-col cols="12" md="12" class="pt-0 mt-0">
              <v-textarea v-model="data.q_3_1" placeholder="" outlined dense disabled>
              </v-textarea>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12" class="ml-4">
              <span><b>3.2. จุดอ่อน</b></span>
            </v-col>
            <v-col cols="12" md="12" class="pt-0 mt-0">
              <v-textarea v-model="data.q_3_2" placeholder="" outlined dense disabled>
              </v-textarea>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12" class="ml-4">
              <span><b>3.3. โอกาส </b></span>
            </v-col>
            <v-col cols="12" md="12" class="pt-0 mt-0">
              <v-textarea v-model="data.q_3_3" placeholder="" outlined dense disabled>
              </v-textarea>
            </v-col>
          </v-row>
          <v-row algn="center" jutify="start" class="text-left" no-gutters>
            <v-col cols="12" class="ml-4">
              <span><b>3.4. อุปสรรค</b></span>
            </v-col>
            <v-col cols="12" md="12" class="pt-0 mt-0">
              <v-textarea v-model="data.q_3_4" placeholder="" outlined dense disabled>
              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span
                ><b
                  >อัพโหลดรายงานผลการดำเนินงานโครงการดำเนินงานชุมชนยั่งยืน</b
                ></span
              >
            </v-col>
            <v-col cols="12" md="12">
              <v-btn @click="seeFile()">ดูเอกสารที่อัพโหลด</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <!-- <v-btn @click="submit()"> บันทึก</v-btn> -->
          </v-row>
          <!-- ///////////////////////////////////////////// -->
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      checkValidate: true,
      lazy: false,
      rules: {
        email: [(v) => !!(v || "").match(/@/) || "Please enter a valid email"],
        // length: (len) => (v) =>
        //   (v || "").length >= len ||
        //   `Invalid character length, required ${len}`,
        maxlength13: [(v) => v.length <= 13 || "Max 13 characters"],
        maxlength10: [(v) => v.length <= 10 || "Max 10 characters"],
        maxAge: [
          (v) => v.length <= 2 || "ระบุอายุระหว่าง 12-60ปี เท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูล",
        ],
        password: [
          (v) =>
            !!(v || "").match(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/
            ) ||
            "Password must contain an upper case letter, a numeric character, and a special character",
        ],
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      data: {
        station: "",
        province: "",
        datefrom: "",
        userId: "",
        staffName: "",
        q_1_1_m: null,
        q_1_1_f: null,
        q_1_2_m: null,
        q_1_2_f: null,
        q_1_3_m: null,
        q_1_3_f: null,
        q_1_4_m: null,
        q_1_4_f: null,
        q_1_1_s: null,
        q_1_2_s: null,
        q_1_3_s: null,
        q_1_4_s: null,
        q_1_5: null,
        q_1_6: null,
        q_1_7: null,
        q_1_8: null,
        q_2_1_1: null,
        q_2_1_2: null,
        q_2_1_3: null,
        q_2_1_4: null,
        q_2_1_5: null,
        q_2_2_1: null,
        q_2_2_2: null,
        q_2_2_3: null,
        q_2_3_1: null,
        q_2_3_2: null,
        q_2_3_3: null,
        q_2_4_1: null,
        q_2_4_2: null,
        q_2_4_3: null,
        q_2_5_1: null,
        q_2_5_2: null,
        q_2_5_3: null,
        q_2_5_4: null,
        q_2_5_5: null,
        q_2_5_6: null,
        q_2_5_7: null,
        q_2_5_8: null,
        q_2_5_8: null,
        q_3_1: null,
        q_3_2: null,
        q_3_3: null,
        q_3_4: null,
        file: "",
      },
      menufrom: false,
      file: "",
      type: "uploadfile",
    };
  },
  async created() {
    var checklogin = JSON.parse(
      Decode.decode(localStorage.getItem("Researchdata"))
    );
    console.log('Researchdata',checklogin);
    this.data.userId = checklogin.userId;
    this.data.station = checklogin.station;
    this.data.province = checklogin.province;
    const response = await this.axios.post(
      `${process.env.VUE_APP_API}/researchForms/findAll?station=` +
        this.data.station,
      {}
    );
    console.log("getstation11111", response.data.data[0]);
    if (response.data.data.length > 0) {
      const responseUser = await this.axios.get(
        `${process.env.VUE_APP_API}/user/getOne/` + response.data.data[0].userId
      );
      console.log("responseUser", responseUser);
      if (responseUser.data.response_status == "SUCCESS") {
        console.log(responseUser);
        this.data = response.data.data[0];
        this.data.datefrom = this.convertDate(response.data.data[0].updatedAt);
        this.data.staffName = responseUser.data.data.name;
        this.data.userId = checklogin.id;
        console.log("logggggg data", this.data);
      } else {
        this.data.staffName = "-";
      }
    }
  },
  methods: {
     q_2_1_5() {
      if (this.data.q_2_1_3 && this.data.q_2_1_2) {
        this.data.q_2_1_5 = (this.data.q_2_1_3 / this.data.q_2_1_2) * 100;
      }
    },
    q_2_2_3() {
      if (this.data.q_2_2_2 && this.data.q_2_2_1) {
        this.data.q_2_2_3 = (this.data.q_2_2_2 / this.data.q_2_2_1) * 100;
      }
    },
    q_2_3_3() {
      if (this.data.q_2_3_2 && this.data.q_2_3_1) {
        this.data.q_2_3_3 = (this.data.q_2_3_2 / this.data.q_2_3_1) * 100;
      }
    },
    q_2_4_3() {
      if (this.data.q_2_4_2 && this.data.q_2_4_1) {
        this.data.q_2_4_3 = (this.data.q_2_4_2 / this.data.q_2_4_1) * 100;
      }
    },
    q_2_5_7() {
      if (
        this.data.q_2_5_2 &&
        this.data.q_2_5_3 &&
        this.data.q_2_5_4 &&
        this.data.q_2_5_5 &&
        this.data.q_2_5_6
      ) {
        this.data.q_2_5_7 =
          this.data.q_2_5_2 * 1 +
          this.data.q_2_5_3 * 1 +
          this.data.q_2_5_4 * 1 +
          this.data.q_2_5_5 * 1 +
          this.data.q_2_5_6 * 1;
        this.data.q_2_5_8 = (this.data.q_2_5_6 / this.data.q_2_5_1) * 100;
      }
    },
    summary1_1() {
      if (this.data.q_1_1_m && this.data.q_1_1_f) {
        this.data.q_1_1_s = this.data.q_1_1_m * 1 + this.data.q_1_1_f * 1;
      }
    },
    summary1_2() {
      if (this.data.q_1_2_m && this.data.q_1_2_f) {
        this.data.q_1_2_s = this.data.q_1_2_m * 1 + this.data.q_1_2_f * 1;
      }
    },
    summary1_3() {
      if (this.data.q_1_3_m && this.data.q_1_3_f) {
        this.data.q_1_3_s = this.data.q_1_3_m * 1 + this.data.q_1_3_f * 1;
      }
    },
    summary1_4() {
      if (this.data.q_1_4_m && this.data.q_1_4_f) {
        this.data.q_1_4_s = this.data.q_1_4_m * 1 + this.data.q_1_4_f * 1;
      }
    },
    seeFile() {
      window.open(this.data.file);
    },
    convertDate(date) {
      return moment(String(date)).format("MM/DD/YYYY hh:mm");
    },
    cancel() {
      this.$router.push("/manageResearchform");
    },
    async searchDropdown() {
      // const response = await this.axios.get(
      //   `https://policesurveyservice.yuzudigital.com/form/getAllStation`
      // );
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation`
      );
      this.items = response.data.data;
      console.log("search", response.data.data);
      this.items.forEach((item) => {
        // item.searchText = item.station + " " + item.village;
        item.searchText = item.station;
      });
    },
    async submit() {
      console.log(this.file1);
      // var checklogin = JSON.parse(Decode.decode(localStorage.getItem("user")));
      // this.data.reviewUserId = checklogin.id;
      // if (this.$refs.createForm.validate(true)) {
      //   this.loading = true;
      //   // console.log("เข้าเฉย");

      //   console.log("data", this.data);
      //   const response = await this.axios.post(
      //     `${process.env.VUE_APP_API}/researchForms/update`,
      //     this.data
      //   );
      //   console.log("response", response);
      //   if (response.data.response_status === "SUCCESS") {
      //     console.log("response", response);
      //     await this.$swal.fire({
      //       showConfirmButton: false,
      //       timer: 1500,
      //       timerProgressBar: true,
      //       icon: "success",
      //       text: "บันทึกข้อมูลสำเร็จ",
      //     });
      //     this.loading = false;
      //     location.reload();
      //   } else {
      //     await this.$swal.fire({
      //       showConfirmButton: false,
      //       timer: 1500,
      //       timerProgressBar: true,
      //       icon: "error",
      //       text: "บันทึกข้อมูลไม่สำเร็จ",
      //     });
      //   }
      // }
    },
    showPicture1(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.imageBase1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          console.log(this.imageBase1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    showPicture2(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.imageBase2 = reader.result;
          this.showImage = URL.createObjectURL(element);
          console.log(this.imageBase2);
        };
      }
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>
